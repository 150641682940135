import moment from 'moment';
import 'owl.carousel';

jQuery(document).ready(function ($) {
    const listenCarousel = $('#listen-carousel')
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="far fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="far fa-chevron-right"></i></div>'


    //Carousel
    listenCarousel.owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        margin: 55,
        responsive: {
            0: {
                items: 1,
                center: true
            },
            768: {
                items: 2,
                center: false
            },
            1200: {
                items: 3
            }
        },
        navText: [
            leftArrow,
            rightArrow
        ]
    })

    // Tourdate Import
    $.ajax({
        url: 'https://rest.bandsintown.com/artists/id_10834483/events?app_id=45PRESS_bunt',
        method: 'GET',
        dataType: 'json',
        error: () => {
            alert('Error fetching events!');
        },
        success: data => {
            const events = $('#tour-dates');
            let html = '';
            if (data.length) {
                for (let event of data) {
                    html += '<div class="event-group">';
                    html += '<div class="event-date">' + moment(event.datetime).format('MMMM DD. YYYY').toUpperCase() + '</div>';
                    html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
                    html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
                    html += '<div class="event-links">';
                    for (let offer of event.offers) {
                        html += '<a href="' + offer.url + '" target="_blank" class="link">' + offer.type.toUpperCase() + '</a>';
                    }
                    html += '<hr/>';
                    html += '</div>';
                    html += '</div>';
                }
                events.html(html);
            } else {
                events.html('<span class="no-events">No upcoming events.</span>');
            }
        }
    });

    // Scroll
    $('.scroll').on('click', function (e) {
        e.preventDefault();
        const href = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(href).offset().top
        }, 2000);
    });
})